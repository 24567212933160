import * as React from "react"
import { Link } from "gatsby"

import dialogueImage from "../images/opendialogue.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"

const blog4 = () => (
  <Layout>
    <SEO title="Open dialogue and not suprises" />
    <div class="container col-lg-6">
      <article class="blog-post mt-3">
        <h2 class="blog-post-title">Open Dialogue And Not Suprises</h2>
        <p class="blog-post-meta">March 09 2021 by Paul</p>
        <img src={dialogueImage} class="card-img-top mb-3" alt="..." />
        <p>
          Are your providers good at communicating and sharing how their company
          is doing in these challenging times? Are you welcoming of their
          communication and see value in such dialogue?
        </p>
        <p>
          If the answer is no, then think carefully about engaging in dialogue
          with your provider as{" "}
          <span class="fw-bold">
            no news is not good news in these challenging times!
          </span>
        </p>
        <p>
          The relocation industry has been stressed financially like no one
          could imagine prior to COVID as we covered in our
          <span>
            {" "}
            <Link to="/blog2">Catastrophic Financial Tsunami blog.</Link>
          </span>{" "}
          This comes after years of pressure on profits, increased compliance,
          and large investments in areas such as technology.
        </p>
        <p>
          Now is a time for honest and open dialogue between global mobility
          stakeholders and providers. Relocation companies are struggling for
          cash and they must make hard decisions on how and where to invest. No
          one should be shocked if a provider lets them know that they cannot
          fulfil all of their original investment plans! Technology maybe an
          area where not every company is able to financially fulfil their plans
          or within agreed time scales. But this might not be a bad thing as
          when companies are financially challenged it forces them to focus on
          key deliverables that will allow the company to prosper.
        </p>
        <p>
          The smart companies will focus on key areas such as how the market is
          evolving and where they truly add value to their clients. As an
          example, this could mean some companies stop providing certain
          services, but improve others for the benefit of clients as we discuss
          in our
          <span>
            {" "}
            <Link to="/blog5">Ecosystem The Future blog.</Link>
          </span>{" "}
        </p>
        <p>
          We have seen relocation companies make decisions to reduce their
          workforce, shut offices, sell parts of their business. Some companies
          have been excellent in communicating such news with their clients
          whilst others have been slow and given mixed messages on such changes.
          If your provider is excellent in communicating such news, even though
          you might not want to hear the news you should value such an open and
          honest relationship.
        </p>
        <p>
          I would highly encourage global mobility professionals to engage with
          their providers by saying they understand these are challenging times
          and you need to understand the impact on their company. Honest and
          open dialogue is needed on the state of the provider and what they can
          achieve, changes they need to make so that everyone&#39;s expectations
          are realigned and managed. No one likes surprises and especially in
          these challenging times!
        </p>
        <p>
          So, what can you do? Take time to ask the right questions of your
          providers and ensure you do not get any unwelcome surprises.
        </p>
        <ol type="1">
          <li class="mb-2">
            Engage with your providers in open dialogue so they share their
            company strategy.
          </li>
          <li class="mb-2">Regularly check providers financials.</li>
          <li class="mb-2">
            Ask questions to ensure their supply chain is secure.
          </li>
          <li class="mb-2">
            If you have concerns with a provider that cannot be rectified make a
            change. There are time and cost-efficient ways to tender and onboard
            a new provider.
          </li>
        </ol>
        <p>
          If you need to review the market or undertake a formal RFP in these
          circumstances, I can help by sharing affective approaches that will
          save you time and deliver the results that you need.
        </p>
        <p>
          Written by Paul Barnes, Director, Inspire Global Mobility Consulting.
        </p>
        <p>
          Email: <strong>paul@igmobility.com</strong> Mobile: +44 (0) 7534 565
          888
        </p>
      </article>
    </div>
  </Layout>
)

export default blog4
